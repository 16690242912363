import { Component, Prop, Vue } from 'vue-property-decorator';
import { SubActivityType } from '@/types/api/info/sub-activity.type';
import { SubActivityLocaleType } from '@/types/api/info/sub-activity-locale.type';
import { Action, Getter } from 'vuex-class';
import { LanguageType } from '@/types/api/info/language.type';
import { LocaleUpdateEvent } from '@/components/ui/locale-updater/localeUpdater';
import { ActivityCard } from '@/store/modules/activity/actions';
import {
  ActivityCreateLocaleRequest,
  ActivitySubCreateRequest, ActivitySubUpdateRequest,
  ActivityUpdateLocaleRequest
} from '@/types/request/activity-request.types';
import { ValidationObserver } from 'vee-validate';
import BTextInputValidation from '@/components/ui/inputs/b-text-input-validation/bTextInputValidation.vue';
import LocaleUpdater from '@/components/ui/locale-updater/localeUpdater.vue';
import BSelectInputValidation from '@/components/ui/inputs/b-select-input-validation/bSelectInputValidation.vue';

@Component({
  name: 'UpdateSubActivityModal',
  components: {
    ValidationObserver,
    BTextInputValidation,
    LocaleUpdater,
    BSelectInputValidation,
  }
})
export default class UpdateSubActivityModal extends Vue {
  tryingToUpdate = false;
  image = null;
  imageUrl = '';
  name = '';
  language = '';
  locales: SubActivityLocaleType[] = [];

  @Prop({
    required: true
  })
  activityId!: number;

  @Prop({
    required: false
  })
  subActivity?: SubActivityType;

  @Prop({
    required: true
  })
  updateCallback!: () => void;

  @Action('activitySubCreate', { namespace: 'activity' })
  activitySubCreate!: (data: ActivitySubCreateRequest) => Promise<SubActivityType>;

  @Action('activitySubUpdate', { namespace: 'activity' })
  activitySubUpdate!: (data: ActivityCard<ActivitySubUpdateRequest>) => Promise<SubActivityType>;

  @Action('activitySubCreateLocale', { namespace: 'activity' })
  activitySubCreateLocale!: (data: ActivityCard<ActivityCreateLocaleRequest>) => Promise<SubActivityLocaleType>;

  @Action('activitySubUpdateLocale', { namespace: 'activity' })
  activitySubUpdateLocale!: (data: ActivityCard<ActivityUpdateLocaleRequest>) => Promise<SubActivityLocaleType>;

  @Getter('getLanguages', { namespace: 'info' })
  languages?: LanguageType[];

  created(): void {
    if (this.subActivity) {
      this.imageUrl = this.subActivity.image;
      this.name = this.subActivity.name;
      this.locales = [...this.subActivity.locales];
    }
  }

  onImageUpload(event) {
    this.image = event;
    this.imageUrl = '';
  }

  get languageOptions() {
    return [
      { value: '', text: '---' },
      ...(this.languages || []).map(item => ({ value: item.code, text: item.code.toUpperCase() }))
    ];
  }

  onUpdateLocale(event: LocaleUpdateEvent) {
    const promise = event.locale
      ? this.activitySubUpdateLocale({ id: event.locale.id, params: event.editedLocale as any })
      : this.activitySubCreateLocale({ id: this.subActivity!.id, params: event.editedLocale as any });

    promise.then(result => {
      this.locales = this.locales.filter(item => item.languageId !== event.language.id);
      this.locales.push(result);
      this.updateCallback();
    });
  }

  onApply() {
    if (!this.tryingToUpdate) {
      this.tryingToUpdate = true;
      const action = this.subActivity
        ? this.activitySubUpdate({
            id: this.subActivity.id,
            params: {
              activityDictId: this.activityId,
              ...(this.image ? { image: this.image as any } : {}),
            }
          })
        : this.activitySubCreate({
          name: this.name,
          language: this.language,
          activityDictId: this.activityId,
          ...(this.image ? { image: this.image as any } : {}),
        });

      action.then(() => {
        this.tryingToUpdate = false;
        this.updateCallback();
      })
      .catch(() => {
        this.tryingToUpdate = false;
      });
      this.cancel();
    }
  }

  cancel() {
    this.$emit('close');
  }
}
