import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import { Action, Getter } from 'vuex-class';
import { ActivityCard } from '@/store/modules/activity/actions';
import { ActivityReferenceUpdateRequest } from '@/types/request/activity-request.types';
import { ActivityType } from '@/types/api/info/activity.type';
import ActivitiesForm from '@/components/pages/activities/activities-form/activitiesForm.vue';
import { LanguageType } from '@/types/api/info/language.type';
import FormBlock from '@/components/ui/form-block/form-block.vue';
import { SubActivityType } from '@/types/api/info/sub-activity.type';
import UpdateSubActivityModal from '@/components/features/modals/update-sub-activity-modal/update-sub-activity-modal.vue';

@Component({
  components: {
    Layout,
    ActivitiesForm,
    FormBlock,
  },
})
export default class ActivitiesUpdate extends Vue {
  eventId!: number;
  activity: ActivityType | null = null;

  @Action('getActivity', { namespace: 'activity' })
  getActivity!: (id: number) => Promise<ActivityType>;

  @Action('updateActivity', { namespace: 'activity' })
  updateActivity!: (data: ActivityCard<ActivityReferenceUpdateRequest>) => Promise<ActivityType>;

  @Action('deleteActivity', { namespace: 'activity' })
  deleteActivity!: (id: number) => Promise<ActivityType>;

  @Action('activitySubDelete', { namespace: 'activity' })
  activitySubDelete!: (id: number) => Promise<SubActivityType>;

  @Getter('getLanguages', { namespace: 'info' })
  languages?: LanguageType[];

  created(): void {
    if (this.$route.params.id) {
      this.eventId = Number(this.$route.params.id);
      this.getActivityRequest().catch(() => this.goToActivities());
    } else {
      this.goToActivities();
    }
  }

  getActivityRequest() {
    return this.getActivity(this.eventId)
      .then(result => {
        this.activity = result;
        return result;
      });
  }

  goToActivities() {
    this.$router.push({ name: 'activities' });
  }

  onApply(params: ActivityReferenceUpdateRequest) {
    return this.updateActivity({
      id: this.eventId,
      params: params
    })
      .then(() => {
        this.goToActivities();
      });
  }

  onDeleteActivity() {
    this.$modal.show('dialog', {
      title: '',
      text: this.$t('common.deleteQuestion'),
      buttons: [
        {
          title: 'Ok',
          handler: () => {
            this.$modal.hide('dialog');
            this.deleteActivity(this.activity!.id)
              .then(() => this.goToActivities());
          }
        },
        {
          title: this.$t('common.cancel')
        }
      ]
    });
  }

  getLanguageTitleById(languageId: number) {
    const lang = this.languages?.find(item => item.id === languageId);
    return lang ? lang.code : '';
  }

  onAddSubActivity() {
    this.$modal.show(UpdateSubActivityModal, {
      updateCallback: () => this.getActivityRequest(),
      activityId: this.activity!.id,
    }, {
      adaptive: true,
      height: 'auto',
    });
  }

  onChangeSubActivity(item: SubActivityType) {
    this.$modal.show(UpdateSubActivityModal, {
      updateCallback: () => this.getActivityRequest(),
      activityId: this.activity!.id,
      subActivity: item,
    }, {
      adaptive: true,
      height: 'auto',
    });
  }

  onDeleteSubActivity(item: SubActivityType) {
    this.$modal.show('dialog', {
      title: '',
      text: this.$t('common.deleteQuestion'),
      buttons: [
        {
          title: 'Ok',
          handler: () => {
            this.$modal.hide('dialog');
            this.activitySubDelete(item.id)
              .then(() => this.getActivityRequest());
          }
        },
        {
          title: this.$t('common.cancel')
        }
      ]
    });
  }
}
